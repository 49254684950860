import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"

class Header extends React.Component {
    render() {
        return (
          <div className="menu-main sticky-top">
            <div className="container">
                <nav class="navbar navbar-expand-lg navbar-light">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <a class="navbar-brand" href="/">
                  <h1>Barcelona FCVN</h1>
                </a>
              
                <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                  <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                    <li class="nav-item active">
                      <a class="nav-link" href="/">Trang chủ</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/category/tin-tuc">Tin Tức</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/category/tin-chuyen-nhuong">Tin Chuyển Nhượng</a>
                    </li>
                  </ul>
                </div>
              </nav>
              </div>
          </div>
              )
            }
        }
        
export default Header;