import React from 'react';
import { Link } from "gatsby"
class Footer extends React.Component {

    render() {
        return (
            <footer className="bg-gray text-light footer-long">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <span className="h5">Cộng đồng Barçamania Việt Nam (FCBVN) là hội những người hâm mộ CLB Barcelona tại Việt Nam.</span>
                </div>
          </div>  
        </div>
      </footer>
        )
    }
}

export default Footer;