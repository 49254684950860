import React, { Fragment } from "react"
import { Link } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row, Col } from 'react-bootstrap';
import "./Layout.css";
import Siderbar from "./Sidebar";

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            padding: 10
          }}
          title={title}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <span
          style={{
            padding: 10
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </span>
      )
    }
    return (
      <Fragment>
        <Header tagName={header} />
        <section className="space-sm flush-with-above bg--secondary">
        <div className="container">
              {children}
              {/* <Siderbar /> */}
            
            </div>
            </section>
        
        <Footer />
      </Fragment>
    )
  }
}

export default Layout
